<template>
  <v-row>
    <v-col cols='2' class="d-flex ">
      <v-btn
          :disabled='isDisabled'
          color='primary'
          depressed
          min-width='250'
          @click='exportFile'
      >
        Export Company Status
      </v-btn>
      <v-btn
          :disabled='isDisabled'
          color='primary'
          depressed
          outlined
          min-width='250'
          @click='exportEmployees'
          class="ml-5"
      >
        Export Employee Status
        <v-icon
            class='white--text'
            color='primary'
            right
            small
        >
          mdi-export-variant
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>

</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'ExportReportingButton',
  props: {
    isDisabled: {
      type: Boolean,
      required: true
    },
    selectedMembers: {
      type: Array,
      required: true
    },
    filteredMembers: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState({
      selectedElectionId: s => s.electionStore.selectedElection.id,
      reportingData: s => s.adminStore.reportingData
    })
  },
  methods: {
    exportFile() {
      let membersIds = [];
      if (this.selectedMembers.length === 0) {
        membersIds = JSON.stringify(this.filteredMembers);
      } else {
        const intersection = this.filteredMembers.filter(element => {
          return this.selectedMembers.includes(element);
        });
        membersIds = JSON.stringify(intersection);
      }
      location.href = `${ process.env.VUE_APP_BASE_URL }business/reporting/companies/${ this.selectedElectionId }/export?ids=${membersIds}`
    },
    exportEmployees() {
      let membersIds = [];
      if (this.selectedMembers.length === 0) {
        membersIds = JSON.stringify(this.filteredMembers);
      } else {
        const intersection = this.filteredMembers.filter(element => {
          return this.selectedMembers.includes(element);
        });
        membersIds = JSON.stringify(intersection);
      }
      location.href =  `${ process.env.VUE_APP_BASE_URL }business/reporting/companies/employees-overview-exports/${ this.selectedElectionId }?ids=${membersIds}`
    },
  }
}
</script>

<style scoped>

</style>