<template>
  <v-card class='rounded-lg elevation-3 ma-5 pa-5'>
    <v-card-title class='font-weight-bold headline pa-0 mb-3'>Company Reporting</v-card-title>
    <change-election
      :elections='elections'
      :loading='loadingElections'
      :selectedElection='selectedElection'
      :title='sections.change_election_reporting'
      class='mb-3'
    />
    <company-reporting-filter @filterApplied='handleMembersSelected'/>
    <company-reporting-table :loading='loading' @selectedMembers="handleExportSelectedMembers"/>
    <export-reporting-button :isDisabled='loading || loadingElections'
                             :selectedMembers="exportSelectedMembers"
                             :filteredMembers="selectedMembersIds"
    />
  </v-card>
</template>

<script>
import CompanyReportingTable from '../components/CompanyReporting/CompanyReportingTable'
import CompanyReportingFilter from '../components/CompanyReporting/CompanyReportingFilter'
import ExportReportingButton from '../components/CompanyReporting/ExportReportingButton'
import ChangeElection from '../components/change-election/change-election'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'CompanyReportingPage',
  components: {
    ChangeElection,
    CompanyReportingTable,
    CompanyReportingFilter,
    ExportReportingButton
  },
  data() {
    return {
      loading: true,
      loadingElections: true,
      selectedMembersIds: [],
      exportSelectedMembers: []
    }
  },
  computed: {
    ...mapState({
      elections: s => s.electionStore.adminElections,
      selectedElection: s => s.electionStore.selectedElection,
      sections: s => s.cmsStore.sections
    })
  },
  watch: {
    selectedElection: {
      async handler() {
        await this.getReporting()
      },
      deep: true
    }
  },
  async created() {
    this.SET_COMPANY_REPORTING([])
    this.loadingElections = true
    await this.getAdminElections()
    this.loadingElections = false
    await this.getReporting()
  },
  methods: {
    ...mapActions({
      getAdminElections: 'electionStore/getAdminElections',
      getCompanyReporting: 'adminStore/getCompanyReporting'
    }),
    ...mapMutations({
      SET_COMPANY_REPORTING: 'adminStore/SET_COMPANY_REPORTING'
    }),
    async getReporting() {
      this.loading = true
      await this.getCompanyReporting(this.selectedMembersIds)
      this.loading = false
    },
    async handleMembersSelected(selectedMembers) {
      this.selectedMembersIds = selectedMembers;
      await this.getReporting()
    },
    async handleExportSelectedMembers(selectedMembers) {
      this.exportSelectedMembers = selectedMembers;
    }
  }
}
</script>