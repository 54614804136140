var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.companyReporting,"loading":_vm.loading,"loading-text":"Please wait... Reporting data is loading","show-select":"","checkboxColor":"primary","item-key":"id"},scopedSlots:_vm._u([{key:"item.loggedIn",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.loggedInPercent)+"% | "+_vm._s(item.loggedInCount))])]}},{key:"item.registered",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.registeredPercent)+"% | "+_vm._s(item.registeredCount))])]}},{key:"item.eligible",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.eligiblePercent)+"% | "+_vm._s(item.eligibleCount))])]}},{key:"item.employeesScheduled",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.employeesScheduledPercent)+"% | "+_vm._s(item.employeesScheduledCount))])]}},{key:"item.employeesRegistered",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.employeesRegisteredPercent)+"% | "+_vm._s(item.employeesRegisteredCount))])]}},{key:"item.employeesEligible",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.employeesEligiblePercent)+"% | "+_vm._s(item.employeesEligibleCount))])]}}]),model:{value:(_vm.preSelectedMembers),callback:function ($$v) {_vm.preSelectedMembers=$$v},expression:"preSelectedMembers"}})}
var staticRenderFns = []

export { render, staticRenderFns }