<template>
  <v-data-table
    :headers='headers'
    :items='companyReporting'
    :loading='loading'
    v-model='preSelectedMembers'
    loading-text='Please wait... Reporting data is loading'
    show-select
    checkboxColor='primary'
    item-key="id"
  >
    <template #item.loggedIn='{item}'>
      <span>{{ item.loggedInPercent }}% | {{ item.loggedInCount }}</span>
    </template>
    <template #item.registered='{item}'>
      <span>{{ item.registeredPercent }}% | {{ item.registeredCount }}</span>
    </template>
    <template #item.eligible='{item}'>
      <span>{{ item.eligiblePercent }}% | {{ item.eligibleCount }}</span>
    </template>
    <template #item.employeesScheduled='{item}'>
      <span>{{ item.employeesScheduledPercent }}% | {{ item.employeesScheduledCount }}</span>
    </template>
    <template #item.employeesRegistered='{item}'>
      <span>{{ item.employeesRegisteredPercent }}% | {{ item.employeesRegisteredCount }}</span>
    </template>
    <template #item.employeesEligible='{item}'>
      <span>{{ item.employeesEligiblePercent }}% | {{ item.employeesEligibleCount }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CompanyReportingTable',
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    preSelectedMembers: [],
    headers: [
      { text: 'Name of Company', align: 'start', value: 'memberName', sortable: false },
      { text: 'Total Employees', align: 'start', value: 'totalEmployees', sortable: false },
      { text: 'Logged in', align: 'start', value: 'loggedIn', sortable: false },
      { text: 'Registered', align: 'start', value: 'registered', sortable: false },
      { text: 'Eligible', align: 'start', value: 'eligible', sortable: false },
      { text: 'Scheduled', align: 'start', value: 'employeesScheduled', sortable: false },
      { text: 'Of registered that voted', align: 'start', value: 'employeesRegistered', sortable: false },
      { text: 'Of Eligible that voted', align: 'start', value: 'employeesEligible', sortable: false }
    ],
  }),
  watch: {
    preSelectedMembers: {
      async handler() {
        const selectedMembersIds = this.preSelectedMembers.map(obj => obj.id);
        this.$emit('selectedMembers', selectedMembersIds);
      },
      deep: true
    }
  },
  computed: mapState('adminStore', ['companyReporting'])
}
</script>

<style scoped>

</style>